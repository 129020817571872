import TitleImage from "./assets/svgs/title.icon.svg";
import React from "react";
import Home from "./Home";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Page404 from "./Page404";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const topAnchor = document.getElementById("header");
    if (topAnchor) {
      topAnchor.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.pathname]);

  return (
    <>
      <header className="header" id="header">
        <button className="link-btn" onClick={() => navigate("/")}>
          <img className="title-icon" src={TitleImage} alt="Title Icon" />
        </button>
      </header>
      <section className="main-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/legal/terms" element={<TermsOfService />} />
          <Route path="/legal/privacy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </section>
      <section className="legal">
        <button
          disabled={location.pathname === "/legal/privacy"}
          className="link-btn"
          onClick={() => navigate("/legal/privacy")}
        >
          Privacy Policy
        </button>
        <button
          disabled={location.pathname === "/legal/terms"}
          className="link-btn"
          onClick={() => navigate("/legal/terms")}
        >
          Terms of Service
        </button>
      </section>
    </>
  );
}

export default App;

const Footer = () => {
  return (
    <footer className="footer">
      <section className="footer-top">
        <section className="footer-li">
          <span>Doesn’t track you</span>
        </section>
        <section className="footer-li">
          <span>Fully open-source</span>
        </section>
        <section className="footer-li">
          <span>Free to use</span>
        </section>
      </section>
      <section className="footer-bottom">
        <section className="footer-li">
          <span>Access to thoussands of Sentinel hosted servers</span>
        </section>
        <section className="footer-li">
          <span>Sponsored by WIF community</span>
        </section>
      </section>
    </footer>
  );
};

export default Footer;

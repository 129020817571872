import React from "react";
import MainRightBG from "./assets/pngs/main-right-bg.png";
import GooglePlayImage from "./assets/svgs/google-play.svg";
import AppleStoteImage from "./assets/svgs/apple-store.svg";
import AndroidAPKImage from "./assets/pngs/android-apk.png";
import TreeImage from "./assets/svgs/tree.svg";
import BuiltOnSentinelImage from "./assets/svgs/built-on-sentinel.svg";
import GithubIcon from "./assets/svgs/github.svg";
import TelegramIcon from "./assets/svgs/telegram.svg";

import Footer from "./Footer";
const Home = () => {
  return (
    <>
      <main className="main">
        <p className="title-text">It’s a dVPN wif a hat</p>
        <img className="main-right-bg" src={MainRightBG} alt="" />
        <section className="download">
          <button className="platform" aria-label="platform-google-play">
            <img className="platform-img" src={GooglePlayImage} alt="" />
          </button>
          <button className="platform" aria-label="platform-apple-store">
            <img className="platform-img" src={AppleStoteImage} alt="" />
          </button>
          <button
            onClick={() =>
              window.open(
                "https://files.dogwifhatdvpn.com/com.dogwifhatdvpn.app_1.0.0.apk"
              )
            }
            className="platform"
            aria-label="platform-android-apk"
          >
            <img className="platform-img" src={AndroidAPKImage} alt="" />
          </button>
        </section>
        <section className="social">
          <button
            onClick={() => window.open("https://github.com/dogwifhatdvpndev")}
            className="social-github"
          >
            <img src={GithubIcon} alt="" />
            <span className="social-github-text">Dogwifhat dVPN Github</span>
          </button>
          <button
            onClick={() => window.open("https://t.me/dogwifhatdvpn")}
            className="social-telegram"
          >
            <img src={TelegramIcon} alt="" />
            <span className="social-telegram-text">/dogwifhatdvpn </span>
          </button>
        </section>
        <section className="discover">
          <span className="discover-text">Discover Sentinel:</span>
          <img className="discover-tree" src={TreeImage} alt="" />
          <span
            className="discover-link"
            onClick={() => {
              window.open("https://linktr.ee/sentineldVPN");
            }}
          >
            /sentineldVPN
          </span>
        </section>
        <button
          className="link-btn"
          onClick={() => {
            window.open("https://github.com/sentinel-official/");
          }}
        >
          <img
            className="built-on-sentinel"
            src={BuiltOnSentinelImage}
            alt=""
          />
        </button>
      </main>
      <section className="foot">
        <Footer />
      </section>
    </>
  );
};

export default Home;

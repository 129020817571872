import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="legal-root">
      <span className="legal-title">Privacy Policy</span>
      <span className="legal-subtitle">
        The App Privacy Policy was updated on July 31, 2023.
      </span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Legal Framework</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Data Collection</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Data Use</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Data Storage</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">
        Right to Access, Rectification, Erasure and Portability
      </span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Data Retention</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Data Disclosure</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Modifications to Privacy Policy</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Applicable Law</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Warrant Canary</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
      <span className="legal-heading">Contact information</span>
      <span className="legal-description">
        Sit duis labore eu dolor. Eiusmod nostrud et anim fugiat quis qui.
        Exercitation proident irure pariatur nostrud ullamco laboris in aliqua
        magna tempor veniam exercitation ipsum. In velit exercitation in commodo
        ex deserunt ad mollit id quis reprehenderit aliqua do.
      </span>
    </div>
  );
};

export default PrivacyPolicy;
